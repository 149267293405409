// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/use-root-loader.ts"
);
import.meta.hot.lastModified = "1737112068049.3508";
}
// REMIX HMR END

import { useMatches } from '@remix-run/react';
import { loader as rootLoader, RootLoaderData } from '~/root';

export function useRootLoader(): RootLoaderData {
  return useMatches().find((match) => match.id === 'root')!
    .data as RootLoaderData;
}
